import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../models/website-module.props";
import {FooterModuleAsync} from "../modules/footer-module/components/footer-module.async";
import {WebsiteModuleScrollButton} from "./conditional/website-module.scroll-button";

export const WebsiteModuleFooter = observer(({controller}: WebsiteModuleProps) => {
  return (
    <>
      <WebsiteModuleScrollButton controller={controller}/>
      <FooterModuleAsync sportTypeId={controller.appStore.state.context.sportType?.id}/>
    </>
  );
});

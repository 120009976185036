import React, {forwardRef} from 'react';
import Draggable from 'react-draggable';
import {debounce} from "../../../infrastructure/debounce/debounce";
import styles from './floating-button.module.scss';
import classNames from "classnames";
import {FLOATING_BUTTON} from "./floating-button-consts";
import {MOBILE_APP_BAR} from "../misc/mobile-app-bar/mobile-app-bar.consts";

export const FloatingButton = forwardRef(({
                                            onDrag,
                                            onStop,
                                            ButtonComponent,
                                            buttonComponentsProps,
                                            setIsDragging,
                                            isCollision,
                                            setIsCollision,
                                            size,
                                            isRtl
                                          }, drag) => {

  const positionDefaultHeight = window.innerHeight - (size + MOBILE_APP_BAR.height + FLOATING_BUTTON.OFFSET);


  return (
    <Draggable
      defaultPosition={{
        x: (isRtl ? -1 : 1) * FLOATING_BUTTON.BUTTON_DEFAULT_POSITION.X,
        y: positionDefaultHeight
      }}
      bounds='parent'
      allowAnyClick={true}
      onDrag={debounce(onDrag, 10)}
      onStop={debounce(onStop, 10)}>
      <div
        ref={drag}
        className={classNames(styles.draggable)}
        style={{
          width: size,
          height: size,
        }}>
        <ButtonComponent {...buttonComponentsProps}/>
      </div>
    </Draggable>
  )
})




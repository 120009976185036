import {BottomMessageModuleTerms} from "./bottom-message-module.terms";
import {BaseConfig} from "../../../../infrastructure/config/base-config";
import type {BottomMessageModuleRemoteConfig} from "./bottom-message-module.remote-config";

export class BottomMessageModuleConfig extends BaseConfig {

  static injectables = [BottomMessageModuleTerms];

  constructor(terms: BottomMessageModuleTerms, remoteConfig: BottomMessageModuleRemoteConfig){
    super(' bottomMessageModule', null, terms, remoteConfig);
  }
}

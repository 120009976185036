import {BaseLinkParams} from "../../../infrastructure/models/link/base-link-params";

export class DynamicPagesLinkParams extends BaseLinkParams  {
  pageName: String;

  constructor(pageName: String) {
    super();

    this.pageName = pageName;
  }

}

import React from 'react';
import {Path} from "../components/svg/components/path";
import {Svg} from "../components/svg/components/Svg";
import {useThemeColor} from "../../infrastructure/hooks/useThemeColor";
import {COLORS} from "../app/consts/colors";

export const Clear = ({style, className, onClick}) => {
  const defaultStyle =  { fill: useThemeColor(COLORS.whitePrimary), width: 24, height: 24, cursor: 'pointer'};
  const assignedStyle = Object.assign(defaultStyle,style);
  const fullStyle = !className ? assignedStyle : {}

  const clearProps = {
    className,
    style: fullStyle,
    viewBox: `0 0 ${assignedStyle.width} ${assignedStyle.height}`,
    onClick: () => onClick && onClick()
  }

  return <Svg {...clearProps}>
    <Path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
  </Svg>
}

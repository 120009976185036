import {ModuleController} from "../../../../infrastructure/controllers/module-controller";
import {BaseManagedService} from "../../../../infrastructure/domain-services/base-managed-service";
import {BottomMessageModuleConfig} from "./bottom-message-module.config";
import {BottomMessageModuleStore} from "./bottom-message-module.store";
import {BottomMessageModuleHandlers} from "./handlers/bottom-message-module.handlers";

export class BottomMessageModuleController extends ModuleController {
  static injectables = [...BaseManagedService.injectables, BottomMessageModuleConfig, BottomMessageModuleStore, BottomMessageModuleHandlers];

}

import {BaseAnalyticsEvent} from "../../../infrastructure/models/analytics/base-analytics-event";
import {GoogleAnalyticsEventModel} from "../../../third-party-services/analytics/google-analytics/google-analytics-event-model";
import {WEBSITE_MODULE} from "../../../apps/website/website-module.consts";

export class ExternalAdClickEvent extends BaseAnalyticsEvent {
  unit: String;
  layoutType: Number;

  constructor(params: ExternalAdClickEvent) {
    super('');

    this.layoutType = params.layoutType;
    this.unit = params.unit;
  }

  get googleAnalyticsEvent(): null {
    const eventName = WEBSITE_MODULE.GOOGLE_ANALYTICS.EVENTS.externalAdClick;

    return new GoogleAnalyticsEventModel(eventName, {
      layoutType: this.layoutType,
      unit: this.unit,
      interaction_type: 'clicked'
    })
  }
}

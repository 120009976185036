import React from 'react';
import {BaseProps} from "../../../infrastructure/models/props/base-props";
import {WebsiteModuleTerms} from "../website-module.terms";
import {WEBSITE_MODULE} from "../website-module.consts";
import styles from "../styles/website-selection-toaster.module.scss";
import classNames from "classnames";
import {observer} from "mobx-react";
import {THEME} from "../../../core/app/consts";

export class WebsiteModuleSelectionToasterProps extends BaseProps {
  isVisible: Boolean;
  text: String;
  i18n: WebsiteModuleTerms;
}

export const WebsiteModuleSelectionToaster = observer(({controller}: WebsiteModuleSelectionToasterProps) => {
  const {state} = controller;
  const {lastUserSelection, shouldRenderBottomNavigationBar, isBottomNavigationVisible} = state;

  const onUndoClick = (e) => {
    e.stopPropagation();
    controller.action(WEBSITE_MODULE.ACTION_TYPES.userSelectionToaster);
  }

  const undoButtonProps = {
    className: classNames(styles.selection_toaster_undo_button),
    onClick: (e) => onUndoClick(e)
  }

  return (
    controller.state.device.isSmallView ?
      <div data-theme={THEME.DARK} className={classNames(styles.selection_toaster, {[styles.selection_toaster_visible]: Boolean(lastUserSelection)}, {[styles.bottom_navigation_visible]: isBottomNavigationVisible && shouldRenderBottomNavigationBar})}>
        <div className={classNames(styles.selection_toaster_text)}>
          {
            controller.state.userSelectionsToasterText
          }
        </div>
        <div {...undoButtonProps}>
          {
            controller.terms.WEB_MY_SCORES_UNDO_REMOVE_BUTTON
          }
        </div>
      </div> : null
  )
});

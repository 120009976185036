import React, {useEffect} from 'react';
import {UseIoC} from "../../composers/composer-plugins/use-ioc";
import {IOC} from "../../../../infrastructure/ioc";
import {DomUtils} from "../../../../infrastructure/utils/dom-utils";
import {BaseProps} from "../../../../infrastructure/models/props/base-props";

export class BackgroundBlockerComponentProps extends BaseProps {
  onDisable: Function;
  blanket: Boolean;
  disableClick: Boolean;
  ioc: IOC;
}

export const BackgroundBlocker = UseIoC()((props: BackgroundBlockerComponentProps) => {
  const domUtils: DomUtils = props.ioc.getInstance(DomUtils);

  const clickEvent = () => {
    !props.disableClick && props.onDisable && props.onDisable()
  };

  useEffect(() => {
    let mounted = true;

    if(mounted) {
      setTimeout(() => domUtils.createEvent('click', clickEvent), 0);
    }

    return () => {
      mounted = false;
      domUtils.removeEvent('click', clickEvent);
    };
  }, []);

  const blanketProps = {
    className: ['background-blocker', 'background-blocker-blanket'].join(' '),
    onClick: () => props.onDisable && props.onDisable()
  };

  return (
    <>
      {
        props.blanket && <div {...blanketProps} >{props.children || null}</div>
      }
      {
        !props.blanket && (props.children || null)
      }
    </>
  );
});

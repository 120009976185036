import {WebToAppCtaModuleController} from "../web-to-app-cta-module.controller";
import {WEB_TO_APP_CTA_CONSTS} from "../web-to-app-cta-module.consts";
import {WebToAppCtaModuleState} from "../models/web-to-app-cta-module.state";
import {AnalyticsEventHandler} from "../../../../../infrastructure/handlers/analytics-event-handler";
import {WebToAppCtaModuleDisplayAnalyticsEvent} from "../models/web-to-app-cta-module.display.analytics-event";
import {AnalyticsService} from "../../../../../third-party-services/analytics/analytics-service";

export class WebToAppCtaModuleDisplayHandler extends AnalyticsEventHandler {
  type = WEB_TO_APP_CTA_CONSTS.ACTION_TYPES.display;
  controller: WebToAppCtaModuleController;
  state: WebToAppCtaModuleState;

  static injectables = [...AnalyticsEventHandler.injectables];

  constructor(analyticsService: AnalyticsService) {
    super(analyticsService);

    this.eventType = WebToAppCtaModuleDisplayAnalyticsEvent;
  }

  async action(params) {
    const sendParams = {
      ...params,
      screen: this.getScreen(this.state.currentLinkModel.name),
    }

    super.action(sendParams);
  }
}

import {BottomMessageModuleClickHandler} from "./bottom-message-module.click-handler";
import {TimingHandler} from "../../../../../infrastructure/handlers/timing-handler";
import {PopulateDataHandler} from "../../../../../infrastructure/handlers/populate-data-handler";
import {HandlerList} from "../../../../../infrastructure/handlers/handler-list";
import {BottomMessageModuleFetchHandler} from "./bottom-message-module.fetch-handler";
import {WebsiteWidgetWizardAutoDisplayHandler} from "../../../handlers/website-widget.wizard-auto-display.handler";
import {PrivacySettingsPopupDisplayHandler} from "../../../../../core/handlers/privacy-settings/display/privacy-settings.popup-display.handler";
import {PrivacySettingsVendorsDisplayHandler} from "../../../../../core/handlers/privacy-settings/display/privacy-settings.vendors-display.handler";
import {PrivacySettingsPurposesDisplayHandler} from "../../../../../core/handlers/privacy-settings/display/privacy-settings.purposes-display.handler";
import {PrivacySettingsSourceChangeHandler} from "../../../../../core/handlers/privacy-settings/change/privacy-settings.source-change.handler";
import {PrivacySettingsPopupClickHandler} from "../../../../../core/handlers/privacy-settings/click/privacy-settings.popup-click.handler";
import {PrivacySettingsPurposesClickHandler} from "../../../../../core/handlers/privacy-settings/click/privacy-settings.purposes-click.handler";
import {PrivacySettingsVendorsClickHandler} from "../../../../../core/handlers/privacy-settings/click/privacy-settings.vendors-click.handler";
import {DidomiNoticeInitHandler} from "../../../../../core/handlers/didomi-notice.init.handler";

export class BottomMessageModuleHandlers extends HandlerList {

  static injectables = [
    BottomMessageModuleClickHandler,
    BottomMessageModuleFetchHandler,
    WebsiteWidgetWizardAutoDisplayHandler,
    PrivacySettingsPopupDisplayHandler,
    PrivacySettingsVendorsDisplayHandler,
    PrivacySettingsPurposesDisplayHandler,
    PrivacySettingsSourceChangeHandler,
    PrivacySettingsPopupClickHandler,
    PrivacySettingsPurposesClickHandler,
    PrivacySettingsVendorsClickHandler,
    DidomiNoticeInitHandler,
    TimingHandler,
    PopulateDataHandler
  ];

  constructor(
    bottomMessageModuleClickHandler: BottomMessageModuleClickHandler,
    bottomMessageModuleFetchHandler: BottomMessageModuleFetchHandler,
    websiteWidgetWizardAutoDisplayHandler: WebsiteWidgetWizardAutoDisplayHandler,
    privacySettingsPopupDisplayHandler: PrivacySettingsPopupDisplayHandler,
    privacySettingsVendorsDisplayHandler: PrivacySettingsVendorsDisplayHandler,
    privacySettingsPurposesDisplayHandler: PrivacySettingsPurposesDisplayHandler,
    privacySettingsSourceChangeHandler: PrivacySettingsSourceChangeHandler,
    privacySettingsPopupClickHandler: PrivacySettingsPopupClickHandler,
    privacySettingsPurposesClickHandler: PrivacySettingsPurposesClickHandler,
    privacySettingsVendorsClickHandler: PrivacySettingsVendorsClickHandler,
    didomiNoticeInitHandler: DidomiNoticeInitHandler,
    timingHandler: TimingHandler,
    populateDataHandler: PopulateDataHandler
  ) {
    super();

    [
      bottomMessageModuleClickHandler,
      bottomMessageModuleFetchHandler,
      websiteWidgetWizardAutoDisplayHandler,
      privacySettingsPopupDisplayHandler,
      privacySettingsVendorsDisplayHandler,
      privacySettingsPurposesDisplayHandler,
      privacySettingsSourceChangeHandler,
      privacySettingsPopupClickHandler,
      privacySettingsPurposesClickHandler,
      privacySettingsVendorsClickHandler,
      didomiNoticeInitHandler,
      timingHandler,
      populateDataHandler
    ].forEach(handler => this.add(handler));
  }
}

import './prototype-extentions';
import {GlobalState} from "../core/bootstrap/global-state";
import {InversionContainer} from "../inversion";
import {singleton} from "./decorators/singleton";
import {Type} from "./type";

const STATE_NAME = '_365SCORES';

@singleton
export class IOC {
  container: InversionContainer;
  globalState;
  registeredNames: Array<String>;
  timestamp;
  localState = {};

  static injectables = [];

  constructor() {
    this.container = new InversionContainer();
    this.globalState = new GlobalState(STATE_NAME);
    this.registeredNames = [];
    this.timestamp = Date.now();

    window[STATE_NAME] = this.localState;
    window[STATE_NAME].ioc = this;

    this.container.assign(IOC, this)
  }

  registerSingleton(type, instance) {
    this.container.registerSingleton(type, instance);
    this.registeredNames.push(type.name);
  }

  getInstance(type) {
    const instance = this.container.getInstance(type);

    // this.localState[type.name] = instance;

    return instance;
  }

  replaceInstance(type, instance) {
    this.container.assign(type, instance);
  }

  getAllInstanceOf(type: Type) {
    return this.container.getAllInstanceOf(type);
  }
}

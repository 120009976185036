import classnames from "classnames";
import styles from './external-ad.module.scss';
import {useDisplayAdByLayout} from "../../../../infrastructure/hooks/useDisplayAdByLayout";
import {useEffect, useState} from "react";
import {SessionStorageUtils} from "../../../../infrastructure/utils/session-storage.utils";
import {SESSION_STORAGE_KEYS} from "../../../consts";
import {useExternalAdAnalyticsEvent} from "../../../../infrastructure/hooks/useExternalAdAnalyticsEvent";
import {Clear} from "../../../icons/clear";

export const ExternalAdContent = ({
                                    controller,
                                    config,
                                    className,
                                    isMock = false,
                                    shouldRenderClosingButton = false,
                                    onClose = null,
                                    FallbackComponent = null
                                  }) => {
  if (!config) {
    return null;
  }
  const {adUnit, width, height, validLayouts} = config;
  const [currentSessionAdLayout, setCurrentSessionAdLayout] = useState(null);


  useEffect(() => {
    const sessionStorage: SessionStorageUtils = controller.props.ioc.getInstance(SessionStorageUtils);
    setCurrentSessionAdLayout(sessionStorage.get(SESSION_STORAGE_KEYS.externalAdLayoutDisplay));
  }, []);

  const shouldDisplayAd = useDisplayAdByLayout(controller, validLayouts);

  if (!shouldDisplayAd) {
    return FallbackComponent ? FallbackComponent : null;
  }

  const handleCloseAd = () => {
    onClose?.();
  };

  const {trackDisplayEvent, trackClickEvent} = useExternalAdAnalyticsEvent(controller);

  const handleClickedAd = () => {
    trackClickEvent({layoutType: currentSessionAdLayout, unit: adUnit});
  };

  useEffect(() => {
    if (isMock && adUnit && shouldDisplayAd && currentSessionAdLayout) {
      trackDisplayEvent({layoutType: currentSessionAdLayout, unit: adUnit});
    }
  }, [isMock, adUnit, shouldDisplayAd, currentSessionAdLayout]);


  return isMock ?
      <div className={classnames(styles.mock, styles.container, className)} style={{width, height}}
           onClick={handleClickedAd}>
        {shouldRenderClosingButton && (<Clear className={styles.close_button} onClick={handleCloseAd}/>)}
      </div>
      :
      <div data-aaad="true" data-aa-adunit={adUnit} className={classnames(styles.container, className)}
           onClick={handleClickedAd}>
        {shouldRenderClosingButton && (<Clear className={styles.close_button} onClick={handleCloseAd}/>)}
      </div>
};

import {DisplayEvent} from "../../../infrastructure/models/analytics/display-event";
import {DIDOMI_NOTICE} from "../../consts";

export class PrivacySettingsNoticeDisplayEvent extends DisplayEvent {
  source: DIDOMI_NOTICE.ANALYTICS.SOURCES;
  timeShown: Number;

  constructor(params: PrivacySettingsNoticeDisplayEvent, popupName: String) {
    super(popupName);

    this.source = params.source;
    this.timeShown = params.timeShown;
  }

  get properties(): Object {
    const properties = super.properties;

    properties.source = this.source;
    properties.timeShown = this.timeShown;

    return properties;
  }

  get isPopulated(): Boolean {
    let isPopulated = super.isPopulated;

    isPopulated &= !!this.source;
    isPopulated &= !!this.timeShown;

    return isPopulated;
  }
}
import {WebToAppCtaModuleTerms} from "./web-to-app-cta-module.terms";
import {BaseConfig} from "../../../../infrastructure/config/base-config";

export class WebToAppCtaModuleConfig extends BaseConfig {

  static injectables = [WebToAppCtaModuleTerms];

  constructor(terms: WebToAppCtaModuleTerms){
    super(' bottomMessageModule', null, terms);
  }
}

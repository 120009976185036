import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {BottomMessageModule} from "../../modules/bottom-message-module/components/bottom-message-module";

export const WebsiteModuleBottomMessage = observer(({controller}: WebsiteModuleProps) => {
  return (
    controller.state.shouldRenderDidomiNotice ? <BottomMessageModule/> : null
  )
});

import React from 'react';

import {IOC} from "./infrastructure/ioc";
import reportWebVitals from "./reportWebVitals";
import {createRoot} from 'react-dom/client';
import {BootstrapModuleAsync} from "./core/bootstrap/components/bootstrap.module.async";
import './coralogix-integration';

const rootElements = Array.from(document.querySelectorAll('[data-365-scores-settings]'));
rootElements.push(...(document.querySelectorAll('[data-widget-id]') || []), ...(document.querySelectorAll('[data-scores-container-id]') || []));

const render = (rootElement, props) => {
  const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

  root.render(<BootstrapModuleAsync {...props} />);
}

const renderWithSettings = (rootElement, ioc: IOC) => {
  const widgetData = (rootElement.dataset['365ScoresSettings'] ? window[rootElement.dataset['365ScoresSettings']] : rootElement.dataset);
  const props = {
    ...widgetData,
    ioc,
    rootElement
  }

  render(rootElement, props);
}

const run = () => {
  rootElements.forEach((rootElement) => renderWithSettings(rootElement, new IOC()))
}

run();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./core/bootstrap/components/bootstrap-module', () => rootElements.forEach(rootElement => run()));
  }
}

reportWebVitals(console.log);


import React, {Suspense} from 'react';
import {AsyncComponent} from "../../../../../core/components/router/async-component";
import classNames from "classnames";
import styles from "../styles/site-header.module.scss";

const SiteHeaderModuleAsyncComponent = AsyncComponent(async () => import('./site-header-module' /* webpackChunkName: "site-header-module" */ ));

export const SiteHeaderModuleAsync = (props) => {
  return <Suspense fallback={<div className={classNames(styles.container, {[styles.small_view]: props.isSmallView})}/>}><SiteHeaderModuleAsyncComponent {...props}/></Suspense>
}

import {BaseAnalyticsEvent} from "../../../../../infrastructure/models/analytics/base-analytics-event";

export class WebToAppCtaModuleDisplayAnalyticsEvent extends BaseAnalyticsEvent {
  screen: String;

  constructor(params) {
    super('', 'display', 'cta');

    this.screen = params.screen;
  }

  get properties() {
    const properties = super.properties;

    properties.screen = this.screen;

    return properties;
  }
}

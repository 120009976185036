import {override} from 'mobx';

import {BaseModuleState} from "../../../../../infrastructure/models/base-module-state";
import {BaseMobxState} from "../../../../../infrastructure/mobx/base-mobx-state";
import {AppStore} from "../../../../../core/app/app-store";
import {LoggerUtils} from "../../../../../infrastructure/utils/logger/logger-utils";
import {DatetimeUtils} from "../../../../../infrastructure/utils/datetime-utils";
import {DeviceViewModel} from "../../../../../core/models/device.view-model";
import {TermsUtils} from "../../../../../infrastructure/utils/terms-utils";
import {WebToAppCtaModuleConfig} from "../web-to-app-cta-module.config";
import {NavigationManager} from "../../../../../core/managers/navigation-manager";

export class WebToAppCtaModuleState extends BaseModuleState {

  static injectables = [...BaseMobxState.injectables, NavigationManager, AppStore, LoggerUtils, DatetimeUtils, DeviceViewModel, TermsUtils, WebToAppCtaModuleConfig];

  @override get isPopulated() {
    return true;
  }
}

import {DIDOMI_NOTICE} from "../../consts";
import {ClickEvent} from "../../../infrastructure/models/analytics/click-event";

export class PrivacySettingsNoticeButtonClickEvent extends ClickEvent {
  timeShown: Number;
  clickType: String;
  source: DIDOMI_NOTICE.ANALYTICS.SOURCES;
  purposesIds: Array<String>;
  vendorsIds: Array<String>;

  constructor(params: PrivacySettingsNoticeButtonClickEvent, popupName: String) {
    super('', 'click', popupName);

    this.timeShown = params.timeShown;
    this.clickType = params.clickType;
    this.source = params.source;
    this.purposesIds = params.purposesIds;
    this.vendorsIds = params.vendorsIds;
  }

  get properties() {
    const properties = super.properties;

    properties.timeShown = this.timeShown;
    properties.clickType = this.clickType;
    properties.source = this.source;
    properties.purposesIds = this.purposesIds;
    properties.vendorsIds = this.vendorsIds;

    return properties;
  }
}
import React from 'react';
import {AsyncComponent} from "../../../core/components/router/async-component";

const WizardWidgetAsyncComponent = AsyncComponent(async () => import('./wizard-widget' /* webpackChunkName: "wizard-widget" */ ));

export const WizardWidgetAsync = (props) => {
  return <React.Suspense fallback={<div/>}>
    <WizardWidgetAsyncComponent {...props}/>
  </React.Suspense>
}

import {DIDOMI_NOTICE} from "../../../consts";
import {PrivacySettingsNoticeDisplayHandler} from "./privacy-settings.notice-display.handler";

export class PrivacySettingsPurposesDisplayHandler extends PrivacySettingsNoticeDisplayHandler {
  type = DIDOMI_NOTICE.ACTION_TYPES.purposesDisplay;

  get noticeEventType() {
    return DIDOMI_NOTICE.EVENT_TYPES.purposes
  }

  get noticeStorageKey() {
    return DIDOMI_NOTICE.KEYS.purposes
  }
}

import React from 'react';

import {BrowserRouter} from 'react-router-dom';
import {WebsiteModuleController} from "../website-module.controller";
import {Compose, ComposeSettings} from "../../../core/components/composers/compose";
import {WebsiteModuleProps} from "../models/website-module.props";
import {WebsiteModuleFrame} from "./website-module.frame";
import styles from "../styles/website.module.scss";
import classNames from "classnames";
import {WebsiteModulePopups} from "./website.module.popups";

export class WebsiteModuleComponentProps extends WebsiteModuleProps {
  controller: WebsiteModuleController;
}

export const WebsiteModuleComponent = ({
                                         controller,
                                         pushNotificationRequestPermission,
                                         basename
                                       }: WebsiteModuleComponentProps) => {
  const wizardWidgetProps = {
    pushNotificationRequestPermission: pushNotificationRequestPermission,
    controller: controller,
  }

  const {state: {appState: {dataInitialized}}} = controller;

  return (
    <div
      className={classNames(styles.module, {[styles.small_view]: controller.device.isSmallView})}>
      <BrowserRouter basename={basename}>
        {
          dataInitialized ?
            <>
              <WebsiteModuleFrame controller={controller}/>
              <WebsiteModulePopups {...wizardWidgetProps}/>
            </> : null
        }
      </BrowserRouter>
    </div>
  )
    ;
}

const settings: ComposeSettings = {
  component: WebsiteModuleComponent,
  propsType: WebsiteModuleComponentProps,
  controllerType: WebsiteModuleController,

  createModule: true,
  observe: true,
}

export default Compose(settings);

import React from 'react';
import {Tooltip} from "../../tooltip/tooltip";
import {KeyboardArrowUp} from "../../../icons/keyboard-arrow/keyboard-arrow-up";
import {TOOLTIP_PLACEMENT} from "../../tooltip/tooltip-consts";
import classNames from "classnames";
import styles from "./scroll-button.module.scss";
import {ScrollButtonComposer} from "./scroll-button-composer";

export const ScrollButton = ScrollButtonComposer(({controller, scrollToTop, visible}) => {
  const {terms} = controller;
  const {BACK_TO_TOP} = terms;

  const buttonProps = {
    className: classNames(styles.button_container),
    onClick: scrollToTop,
  };

  const tooltipProps = {
    title: BACK_TO_TOP,
    placement: TOOLTIP_PLACEMENT.top,
  }

  return (
    visible ? <div {...buttonProps}>
      <Tooltip {...tooltipProps}>
        <KeyboardArrowUp className={classNames(styles.arrow)} />
      </Tooltip>
    </div> : null
  );
});

import {override} from 'mobx';

import {BaseModuleState} from "../../../../../infrastructure/models/base-module-state";
import {BaseMobxState} from "../../../../../infrastructure/mobx/base-mobx-state";
import {AppStore} from "../../../../../core/app/app-store";
import {LoggerUtils} from "../../../../../infrastructure/utils/logger/logger-utils";
import {DatetimeUtils} from "../../../../../infrastructure/utils/datetime-utils";
import {DeviceViewModel} from "../../../../../core/models/device.view-model";
import {TermsUtils} from "../../../../../infrastructure/utils/terms-utils";
import {BottomMessageModuleConfig} from "../bottom-message-module.config";
import {NavigationManager} from "../../../../../core/managers/navigation-manager";
import {DynamicPagesLinkModel} from "../../../../../containers/dynamic-pages-container/models/dynamic-pages.link-model";
import {DYNAMIC_PAGES} from "../../../../../core/consts";

export class BottomMessageModuleState extends BaseModuleState {

  static injectables = [...BaseMobxState.injectables, NavigationManager, AppStore, LoggerUtils, DatetimeUtils, DeviceViewModel, TermsUtils, BottomMessageModuleConfig];

  @override get isPopulated() {
    return !this.appState.isCookiesPolicyApproved;
  }

  get privacyLinkModel() {
    return new DynamicPagesLinkModel(DYNAMIC_PAGES.privacy);
  }
}

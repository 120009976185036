import React from 'react';

import styles from "../../styles/website.module.scss";
import classNames from "classnames";
import {observer} from "mobx-react";
import {ScrollBlocker} from "../../../../core/components/misc/blockers/scroll-blocker";
import {NewSearchWidgetAsync} from "../../../../widgets/new-search-widget/components/new-search-widget.async";
import {ACTION_TYPES} from "../../../../core/consts";

export const WebsiteModuleSearchContent = observer(({controller}) => {
  const {state} = controller;
  const {appState: {isSearchVisible}} = state;

  const searchButtonProps = {
    className: classNames(styles.search_content, {[styles.hidden_search_content]: !isSearchVisible}),
    onClick: (e) => e.stopPropagation()
  }

  const scrollBlockerProp = {
    onDisable: () => controller.action(ACTION_TYPES.onSearchClick,{isVisible: false}),
    blanket: true,
    blockScrolling: true,
    blockScrollToTop: true
  };

  return (
    <>
      <div {...searchButtonProps}>
        <NewSearchWidgetAsync isVisible={isSearchVisible}/>
      </div>
      {
        isSearchVisible ? <ScrollBlocker {...scrollBlockerProp}/> : null
      }
    </>
  )
})

import React from 'react';
import {AsyncComponent} from "../../../../../core/components/router/async-component";

const BettingPromotionModuleComponent = AsyncComponent(async () => import('./betting-promotion.module' /* webpackChunkName: "template-module" */ ));

export const BettingPromotionModuleAsync = (props) => {
  return <React.Suspense fallback={<div/>}>
    <BettingPromotionModuleComponent {...props}/>
  </React.Suspense>
};

import React, {useEffect, useState} from 'react';

import {WebToAppCtaModuleProps} from "../models/web-to-app-cta-module.props";
import {Compose, ComposeSettings} from "../../../../../core/components/composers/compose";
import {WebToAppCtaModuleController} from "../web-to-app-cta-module.controller";
import {WebToAppCtaModuleTerms} from "../web-to-app-cta-module.terms";
import {Popup} from "../../../../../core/components/misc/dialog/popup";
import classnames from "classnames";
import styles from '../styles/web-to-app-cta-module.module.scss';
import {WebToAppCtaModuleContent} from "./web-to-app-cta-module.content";
import {WEB_TO_APP_CTA_CONSTS} from "../web-to-app-cta-module.consts";

export class WebToAppCtaModuleComponentProps extends WebToAppCtaModuleProps {
  i18n: WebToAppCtaModuleTerms;
  onClose: Function;
  deepLink: String;
}

export const WebToAppCtaModuleComponent = (({controller, onClose}: WebToAppCtaModuleComponentProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
      controller.action(WEB_TO_APP_CTA_CONSTS.ACTION_TYPES.display);
    }, 50);
  }, []);

  const onClick = (clickType) => {
    setVisible(false);

    controller.action(WEB_TO_APP_CTA_CONSTS.ACTION_TYPES.onClose, {clickType})
    setTimeout(() => onClose(), 300);
  }

  const popupProps = {
    className: classnames(styles.popup, styles.container, {[styles.visible]: visible}),
    onClose: () => onClick(WEB_TO_APP_CTA_CONSTS.CLICK_TYPES.close)
  }

  return <Popup {...popupProps}>
    <WebToAppCtaModuleContent controller={controller} onClick={onClick}/>
  </Popup>
});

const settings: ComposeSettings = {
  component: WebToAppCtaModuleComponent,
  propsType: WebToAppCtaModuleComponentProps,
  controllerType: WebToAppCtaModuleController,

  observe: true,
  createModule: true,
}

export const WebToAppCtaModule = Compose(settings);

import React from 'react-dom'
import {forwardRef} from "react";
import styles from './floating-button.module.scss';
import classNames from "classnames";

export const DropArea = forwardRef(({
                                           isDragging,
                                           isCollision,
                                           size
                                         }, drop) => {

  return (
      <div
        className={classNames(styles.close)}
        id="close"
        ref={drop}
        style={{
          visibility: isDragging ? 'visible' : 'hidden',
          width: size,
          height: size,
          transform: isCollision ? 'scale(1)' : 'scale(0.7)',
          left: `calc(50% - ${size / 2}px)`,
        }}>
      </div>
  )
})

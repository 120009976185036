// eslint-disable-next-line no-extend-native
String.prototype.format = function (params = {}, prefix = '#') {
  let formattedString = this;

  for (const key in params) {
    formattedString = formattedString.replaceAll(prefix + key, params[key]);
  }

  return formattedString;
}

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
}

// eslint-disable-next-line no-extend-native
String.prototype.remove = function (search) {
  const target = this;
  return target.replaceAll(search, '');
}

// eslint-disable-next-line no-extend-native
String.prototype.toDashCase = function () {
  return this.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}


// eslint-disable-next-line no-extend-native
String.prototype.toSnakeCase = function () {
  return this.split(/(?=[A-Z])/).join('_').toLowerCase();
}

// eslint-disable-next-line no-extend-native
String.prototype.ensureHttps = function () {
  const http: String = 'http:';
  const https: String = 'https:';

  const httpsUrl: String = this.replace(http, https);

  return httpsUrl;
}

// eslint-disable-next-line no-extend-native
Array.prototype.last = function (callback = null) {
  if (callback) {
    return this.filter(callback).last();
  }
  return this[this.length - 1];
}
// eslint-disable-next-line no-extend-native
Array.prototype.first = function (callback = null) {
  if (callback) {
    return this.find(callback);
  }

  return this.length > 0 ? this[0] : null;
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortByKeys = function (keys: Array<String>) {
  if (!keys || !keys.length) {
    throw new Error('keys should provide to Array.sortByKeys')
  }

  return this.sort((a, b) => {
    let index: Number = 0;
    let compare: Number = 0;

    do {
      const x = a[keys[index]];
      const y = b[keys[index]];

      compare = compareFn(x, y);
      index++;
    } while (index < keys.length && compare === 0);

    return compare;
  })
}

const compareFn = (a: String, b: String) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}


// eslint-disable-next-line no-extend-native
Element.prototype.documentOffsetTop = function () {
  return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0);
}

// eslint-disable-next-line no-extend-native
Element.prototype.centerVerticalPosition = function () {
  return this.documentOffsetTop() - (document.body.clientHeight / 2);
}

// eslint-disable-next-line no-extend-native
Date.prototype.compare = function (date: Date) {
  const compareResult: Number = compareFn(this.getTime(), date.getTime());

  return compareResult;
}


// eslint-disable-next-line no-extend-native
Array.prototype.isEqual = function (a, b) {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

// eslint-disable-next-line no-extend-native
Array.prototype.swap = function (a, b) {
  if (this.length > 1) {
    let temp = this[a];
    this[a] = this[b];
    this[b] = temp;
  }

  return this;
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function () {
  const isObject = typeof (this[0]) === 'object';

  function onlyUnique(value, index, self) {
    return self.findIndex(x => isObject ? (x.equals(value)) : (x === value)) === index;
  }

  const distinctArray = this.filter(onlyUnique);

  return distinctArray;
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinctBy = function (keys) {
  let answer = [];

  this.forEach(x => {
    if (!answer.some(y => keys.every(key => y[key] === x[key]))) {
      answer.push(x);
    }
  });

  return answer
}

// eslint-disable-next-line no-extend-native
Number.prototype.format = function () {
  return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

// eslint-disable-next-line no-extend-native
String.prototype.urlB64ToUint8Array = function () {
  const padding = '='.repeat((4 - this.length % 4) % 4);
  const base64 = (this + padding)
    //eslint-disable-next-line
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

// eslint-disable-next-line no-extend-native
Number.prototype.mod = function (n) {
  return ((this % n) + n) % n;
};

// eslint-disable-next-line no-extend-native
Number.prototype.shortNumber = function () {
  const numDigits = this.toString().length;
  const oneMillion = 1000000;
  const oneMillionDigits = 7;
  const oneThousand = 1000;
  const oneThousandDigits = 4;

  if (numDigits >= oneMillionDigits)
    return (this / oneMillion).toFixed(2).replace(/\.0$/, '') + 'M';
  if (numDigits >= oneThousandDigits) {
    return (this / oneThousand).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return `${this}`;
}

// eslint-disable-next-line no-extend-native
String.prototype.brightnessBgLevel = function (threshold, darkColor, brightColor) {
  let color = this.replace('#', '');
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  const currentBgBrightness = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);

  const textColor = (currentBgBrightness > threshold) ? darkColor : brightColor;

  return textColor
}

// eslint-disable-next-line no-extend-native
Date.prototype.midnight = function () {
  return new Date(this.setHours(0, 0, 0, 0));
}

// eslint-disable-next-line no-extend-native
Array.prototype.removeNulls = function () {
  return this.filter(x => !!x);
}

// eslint-disable-next-line no-extend-native
String.prototype.addClassToSubString = function (className, style) {
  const headerSpanTag = `<span class=${className} style="${style}">`;
  const newString = this.replaceAll('{{', headerSpanTag).replaceAll('}}', '</span>')

  return newString
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortAscending = function (key) {
  return this.sort((a, b) => compareFn(a[key], b[key]));
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortDescending = function (key) {
  return this.sort((a, b) => compareFn(b[key], a[key]));
}

// eslint-disable-next-line no-extend-native
String.prototype.addColorToSubString = function (color) {
  const headerSpanTag = `<span style="color: ${color}">${this}</span>`;

  return headerSpanTag
}

// eslint-disable-next-line no-extend-native
Array.prototype.rotate = function (count) {
  count -= this.length * Math.floor(count / this.length);
  this.push.apply(this, this.splice(0, count));

  return this
}

// eslint-disable-next-line no-extend-native
String.prototype.convertToNumberArray = function () {
  return this.split(',').map(Number)
}

// eslint-disable-next-line no-extend-native
String.prototype.colorSubString = function (color) {
  const style = `color: ${color}`
  const start = '{{';
  const end = '}}';

  return changeSubString(this, '', style, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.boldSubString = function () {
  const className = 'bold';
  const start = '{&';
  const end = '&}';

  return changeSubString(this, className, null, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.underlineSubString = function () {
  const className = 'underline';
  const start = '{_';
  const end = '_}';

  return changeSubString(this, className, null, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.italicSubString = function () {
  const className = 'italic';
  const start = '{/';
  const end = '/}';

  return changeSubString(this, className, null, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.linkSubString = function (className, url) {
  const start = '{<';
  const end = '>}';

  const headerSpanTag = `<a href=${url} class=${className}>`;
  const newString = this.replaceAll(start, headerSpanTag).replaceAll(end, '</a>')

  return newString
}

const changeSubString = (text, className, style, start, end) => {
  const headerSpanTag = `<span style="${style}" class=${className}>`;
  const newString = text.replaceAll(start, headerSpanTag).replaceAll(end, '</span>')

  return newString
}

// eslint-disable-next-line no-extend-native
String.prototype.subTextStyling = function (color) {
  return this.colorSubString(color).boldSubString().underlineSubString().italicSubString()
}

// eslint-disable-next-line no-extend-native
Date.prototype.diffFromToday = function () {
  let now = new Date();
  let diffInTime = this.getTime() - now.getTime();

  let diff = diffInTime / (1000 * 3600 * 24);

  return parseInt(diff)
}

// eslint-disable-next-line no-extend-native
Array.prototype.sum = function () {
  if (this.length === 0) return 0;

  return this.reduce((a, b) => a += b);
}

// eslint-disable-next-line no-extend-native
String.prototype.toFirstCapitalizeLetter = function () {
  const str = this.toLowerCase();
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);

  return str2;
}

// eslint-disable-next-line no-extend-native
String.prototype.fromHtmlToText = function () {
  const domparser: DOMParser = new DOMParser();
  const doc: HTMLDocument = domparser.parseFromString(this, "text/html");
  const text = doc.body.innerText.replaceAll("  ", " ");

  return text;
}

// eslint-disable-next-line no-extend-native
Number.prototype.amountOfDigits = function () {
  return this.toString().length;
}

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function (keyGetter) {
  const map = new Map();
  this.forEach((item) => {
    let key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// eslint-disable-next-line no-extend-native
String.prototype.hexToRGB = function (alpha) {
  var r = parseInt(this.slice(1, 3), 16),
    g = parseInt(this.slice(3, 5), 16),
    b = parseInt(this.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

// eslint-disable-next-line no-extend-native
String.prototype.splitByLastOccurrence = function(char) {
  const lastIndex = this.lastIndexOf('-');
  const value1 = this.substring(0, lastIndex);
  const value2 = this.substring(lastIndex + 1);

  return [value1, value2];
}

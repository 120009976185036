import React, {forwardRef, useState} from 'react';

import Tippy from '@tippyjs/react';

export const LazyTippy = forwardRef(({eager, plugins, render, content, ...rest}, ref) => {
  const [mounted, setMounted] = useState(eager);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = {...rest};

  computedProps.plugins = [lazyPlugin, ...(plugins || [])];

  if (render) {
    computedProps.render = (...args) => (mounted ? render(...args) : '');
  } else {
    computedProps.content = mounted || eager ? content : '';
  }

  return <Tippy {...computedProps} ref={ref} />;
});

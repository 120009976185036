import {SESSION_STORAGE_KEYS} from "../../core/consts";
import {SessionStorageUtils} from "../utils/session-storage.utils";

export const useDisplayAdByLayout = (controller, adLayouts) => {
    let shouldDisplayAd = false;
    const sessionStorage: SessionStorageUtils = controller.props.ioc.getInstance(SessionStorageUtils);
    const currentSessionAdLayout = sessionStorage.get(SESSION_STORAGE_KEYS.externalAdLayoutDisplay);
    if(currentSessionAdLayout && adLayouts.includes(currentSessionAdLayout)){
      shouldDisplayAd = true;
    }

  return shouldDisplayAd;
};

import {BottomMessageModuleController} from "../bottom-message-module.controller";
import {ActionHandler} from "../../../../../infrastructure/handlers/action-handler";
import {LOCAL_STORAGE_KEYS} from "../../../../../core/consts";
import {LocalStorageUtils} from "../../../../../infrastructure/utils/local-storage.utils";
import {BottomMessageModuleState} from "../models/bottom-message-module.state";
import { runInAction } from "mobx"

export class BottomMessageModuleFetchHandler extends ActionHandler {
  controller: BottomMessageModuleController;
  state: BottomMessageModuleState;

  static injectables = [...ActionHandler.injectables, LocalStorageUtils];

  constructor(localStorageUtils: LocalStorageUtils) {
    super();

    this.localStorageUtils = localStorageUtils;
  }

  onLoaded() {
    super.onLoaded();

    this.action();
  }

  async action() {
    const isCookiesPolicyApproved: Boolean = this.localStorageUtils.get(LOCAL_STORAGE_KEYS.cookiePolicyApproved);

    runInAction(() => {
      this.state.appState.isCookiesPolicyApproved = isCookiesPolicyApproved;
    })
  }
}

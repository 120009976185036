import {BaseModel} from "../models/base-model";
import {BaseUtils} from "./base-utils";

class ContractsUtils extends BaseUtils {

  required(parameter, message = 'Parameter was not supplied') {
    const isValid = typeof parameter !== "undefined";

    if (!isValid) {
      throw new Error(message);
    }

    return isValid;
  }

  requiredType(instance: BaseModel, type, message = instance ? `instance ${instance.constructor.name} should be of type ${type}` : `instance not provided for type`) {
    this.required(instance);

    const isValid = instance instanceof type;

    if (!isValid) {
      throw new Error(message);
    }

    return isValid;
  }
}

export const contractsUtils = new ContractsUtils();

import {AnalyticsService} from "../../../../third-party-services/analytics/analytics-service";
import {LOCAL_STORAGE_KEYS} from "../../../consts";
import {LocalStorageUtils} from "../../../../infrastructure/utils/local-storage.utils";
import {AnalyticsEventHandler} from "../../../../infrastructure/handlers/analytics-event-handler";
import {PrivacySettingsNoticeButtonClickEvent} from "../../../models/events/privacy-settings.notice-button-click.event";

export class PrivacySettingsNoticeClickHandler extends AnalyticsEventHandler {
  analyticsService: AnalyticsService;
  localStorageUtils: LocalStorageUtils;

  constructor(analyticsService: AnalyticsService, localStorageUtils: LocalStorageUtils) {
    super(analyticsService, localStorageUtils);

    this.localStorageUtils = localStorageUtils;
  }

  async action(params) {
    const sendParams = {
      timeShown: this.displayCounter(),
      source: this.appStore.state.appPrivacyDisplayModel.source,
      clickType: params.clickType,
      purposesIds: this.purposesIds,
      vendorsIds: this.vendorsIds,
    }

    const clickEvent: PrivacySettingsNoticeButtonClickEvent = new PrivacySettingsNoticeButtonClickEvent(sendParams, this.noticeEventType);

    await this.analyticsService.createEvent(clickEvent);
  }

  get purposesIds(): String {
    const purposesIds: String = window.didomiState.didomiPurposesConsentDenied

    return purposesIds !== "" ? purposesIds : undefined;
  }

  get vendorsIds(): String {
    const vendorsIds: String = window.didomiState.didomiVendorsConsentDenied

    return vendorsIds !== "" ? vendorsIds : undefined;
  }

  displayCounter() {
    let counter: Number = 1;
    const didomiCounter = this.localStorageUtils.get(LOCAL_STORAGE_KEYS.didomiNoticeCount);

    if (didomiCounter !== undefined && (didomiCounter[this.noticeStorageKey] !== undefined)){
      counter = didomiCounter[this.noticeStorageKey]
    }

    return counter
  }
}

import {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import classNames from "classnames";
import styles from "./modal.module.scss";


export const Modal = (props) => {
  const container = useRef(document.createElement('div'));
  let modalRoot = document.getElementById('modal-root');
  if(modalRoot.childNodes.length > 0){
    const modalRoot2 = document.createElement('div')
    modalRoot2.setAttribute("id", "modal-root-secondary");
    modalRoot2.setAttribute("class", "scores365");
    modalRoot.appendChild(modalRoot2);
    modalRoot = modalRoot2;
  }

  useEffect(() => {
      modalRoot.appendChild(container.current);
      modalRoot.classList.add(classNames(styles.show));
      modalRoot.classList.add(classNames(styles.modal_root));
      container.current.classList.add(classNames(styles.modal_container));

      if (props.isRtl) {
        modalRoot.classList.add('direction-rtl');
        modalRoot.classList.remove('direction-ltr');
      }
      else {
        modalRoot.classList.add('direction-ltr');
        modalRoot.classList.remove('direction-rtl');
      }

      return () => {
        modalRoot.classList.remove(classNames(styles.show));
        modalRoot.classList.remove(classNames(styles.modal_root));
        modalRoot.removeChild(container.current);
      }
    }, []
  )

  return ReactDOM.createPortal(props.children, container.current);
}

import {ImageRequestParams} from "../../../../../infrastructure/models/params/image-request-params";

export class WebToAppCtaImageRequestParams extends ImageRequestParams {
  constructor(width: Number,height: Number) {
    super(width, height);

    this.resourceName = `WebSite/Social_banner`;
    this.version = 4;
  }
}

export const WEB_TO_APP_CTA_CONSTS = {
  ACTION_TYPES: {
    onClose: 'onClose',
    display: 'display',
  },
  CLICK_TYPES: {
    app: 1,
    browser: 2,
    close: 3,
  }
}

import {ARGS} from "./args";
import {BaseModel} from "../../infrastructure/models/base-model";

export class GlobalState extends BaseModel {
  version: ARGS.VERSION_NUMBER;

  constructor(stateName: String) {
    super();

    window[stateName] = this;
  }

}

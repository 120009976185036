import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {WebToAppCtaModule} from "../../modules/web-to-app-cta-module/components/web-to-app-cta-module";
import {WEBSITE_MODULE} from "../../website-module.consts";

export const WebsiteModuleWebToAppCta = observer(({controller}: WebsiteModuleProps) => {
  const webToAppCtaProps = {
    deepLink: controller.state.webToAppCTAModel?.remoteConfigModel?.appDeepLink,
    onClose: () => {
      controller.action(WEBSITE_MODULE.ACTION_TYPES.webTopAppCTAVisibleHandler, {isVisible: false})
    }
  }

  return controller.state.webToAppCTAModel.isVisible ? <WebToAppCtaModule {...webToAppCtaProps} /> : null;
});

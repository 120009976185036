import React, {useContext} from 'react';

import {BaseProps} from "../../../infrastructure/models/props/base-props";
import {WithDevice} from "../composers/with-device";
import {DeviceViewModel} from "../../models/device.view-model";
import {TOOLTIP_DISTANCE, TOOLTIP_PLACEMENT, TOOLTIP_TRIGGER} from "./tooltip-consts";
import {WithUserAgent} from "../composers/with-user-agent";
import {UserAgentViewModel} from "../../models/user-agent.view-model";
import {UseIoC} from "../composers/composer-plugins/use-ioc";
import {ThemeContext} from "../../app/contexts/theme-context";
import {THEME} from "../../app/consts";
import {LazyTippy} from "./lazy-tooltip";
import './tooltip.scss';

export class TooltipProps extends BaseProps {
  device: DeviceViewModel;
  userAgent: UserAgentViewModel;
  className: String;
  title: String;
  content: React.Component;
  interactive: Boolean = false;
  ignoreDeviceWidth: Boolean = false;
  placement: TOOLTIP_PLACEMENT;
  showDelay: Number = 0;
  hideDelay: Number = 0;
  animation: String;
  onShow: Function;
  onHide: Function;
  onCreate: Function;
  disable: Boolean;
  trigger: Boolean;
  hideArrow: Boolean;
  maxWidth: Number;
  distance: Number;
  zIndex: Number;
  arrow: Boolean;
  eager: Boolean;
  visible: Boolean;
  hideOnClick: Boolean;
  onClickOutside: Function;
}

export const Tooltip = UseIoC()(WithDevice(WithUserAgent((props: TooltipProps) => {
  const theme = useContext(ThemeContext);
  const tippyTheme = (theme === THEME.DARK) ? 'dark' : 'light-border';

  if (!props.children) {
    return null;
  }

  if ((!props.ignoreDeviceWidth && props.userAgent?.isHandset) || props.disable || !(props.content || props.title)) {
    return (
      props.className ? <div className={props.className}>
        {
          props.children
        }
      </div> : props.children
    )
  }
  return (
    <LazyTippy
      className={"scores365"}
      content={<div
        className={['tippy-pooper', props.device.isRtl ? 'direction-rtl' : 'direction-ltr'].join(' ')}>{props.content || props.title}</div>}
      theme={tippyTheme}
      placement={props.placement || TOOLTIP_PLACEMENT.top}
      appendTo={document.body}
      interactive={props.interactive}
      trigger={props.trigger || TOOLTIP_TRIGGER.mouseEnterFocus}
      delay={[props.showDelay > -1 ? props.showDelay : 500, props.hideDelay || 0]}
      animation={props.animation}
      onShow={() => props.onShow && props.onShow()}
      onHide={() => props.onHide && props.onHide()}
      onCreate={(instance) => props.onCreate && props.onCreate(instance)}
      maxWidth={props.maxWidth}
      arrow={!props.hideArrow}
      zIndex={props.zIndex}
      eager={props.eager}
      hideOnClick={props.hideOnClick}
      offset={[0, props.distance || TOOLTIP_DISTANCE]}
      visible={props.visible}
      onClickOutside={() => props.onClickOutside?.()}
    >
      <div className={props.className}>
        {
          props.children
        }
      </div>
    </LazyTippy>
  );
})));

import {TimingHandler} from "../../../../../infrastructure/handlers/timing-handler";
import {PopulateDataHandler} from "../../../../../infrastructure/handlers/populate-data-handler";
import {HandlerList} from "../../../../../infrastructure/handlers/handler-list";
import {WebToAppCtaModuleOnClickHandler} from "./web-to-app-cta-module-on-click.handler";
import {WebToAppCtaModuleDisplayHandler} from "./web-to-app-cta-module.display.handler";

export class WebToAppCtaModuleHandlers extends HandlerList {
  static injectables = [
    WebToAppCtaModuleOnClickHandler,
    WebToAppCtaModuleDisplayHandler,
    TimingHandler,
    PopulateDataHandler
  ];

  constructor(
    webToAppCtaModuleOnCloseClickHandler: WebToAppCtaModuleOnClickHandler,
    webToAppCtaModuleDisplayHandler: WebToAppCtaModuleDisplayHandler,
    timingHandler: TimingHandler,
    populateDataHandler: PopulateDataHandler
  ) {
    super();

    [
      webToAppCtaModuleOnCloseClickHandler,
      webToAppCtaModuleDisplayHandler,
      timingHandler,
      populateDataHandler
    ].forEach(handler => this.add(handler));
  }
}

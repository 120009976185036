import React from 'react';

import {BottomMessageModuleProps} from "../models/bottom-message-module.props";
import {Compose, ComposeSettings} from "../../../../../core/components/composers/compose";
import {BottomMessageModuleController} from "../bottom-message-module.controller";
import {BOTTOM_MESSAGE} from "../bottom-message-module.consts";
import {BottomMessageModuleTerms} from "../bottom-message-module.terms";
import {ACTION_TYPES, DIDOMI_NOTICE} from "../../../../../core/consts";
import {AsyncComponent} from "../../../../../core/components/router/async-component";

const DidomiNotice = AsyncComponent(() => import("../../../../../core/components/didomi-notice/didomi-notice" /* webpackChunkName: "didomi" */));

export class BottomMessageModuleComponentProps extends BottomMessageModuleProps {
  i18n: BottomMessageModuleTerms;
  onClick: Function;
}

export const BottomMessageModuleComponent = (({controller}: BottomMessageModuleComponentProps) => {
  const {state} = controller;
  const didomiProps = {
    lang: state.language.didomiCode,
    i18n: controller.state.terms,
    isRtl: controller.device.isRtl,
    onShow: () => controller.action(DIDOMI_NOTICE.ACTION_TYPES.popupDisplay),
    onHidden: () => controller.action(BOTTOM_MESSAGE.ACTION_TYPES.bottomMessageClick),
    popupButtonClick: (clickType) => controller.action(DIDOMI_NOTICE.ACTION_TYPES.popupClick, {clickType}),
    purposesButtonClick: (clickType) => controller.action(DIDOMI_NOTICE.ACTION_TYPES.purposesClick, {clickType}),
    vendorsButtonClick: (clickType) => controller.action(DIDOMI_NOTICE.ACTION_TYPES.vendorsClick, {clickType}),
    onVendorsShow: () => controller.action(DIDOMI_NOTICE.ACTION_TYPES.vendorsDisplay),
    onPurposesShow: () => controller.action(DIDOMI_NOTICE.ACTION_TYPES.purposesDisplay),
    initDidomi: (didomi) => controller.action(ACTION_TYPES.initDidomiHandler, {didomi})
  }

  return <DidomiNotice {...didomiProps}/>
});

const settings: ComposeSettings = {
  component: BottomMessageModuleComponent,
  propsType: BottomMessageModuleComponentProps,
  controllerType: BottomMessageModuleController,

  observe: true,
  createModule: true,
}

export const BottomMessageModule = Compose(settings);

import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {DynamicPopup} from "../../../../core/components/dynamic-popup/dynamic-popup";

export const WebsiteModuleDynamicPopup = observer(({controller}: WebsiteModuleProps) => {
  const {state} = controller;
  const {appState, sportTypeId} = state;
  const {dynamicPopupModel} = appState;

  return dynamicPopupModel && sportTypeId > 0 ? <DynamicPopup controller={controller} sportTypeId={sportTypeId}/> : null;
});

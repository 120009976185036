import React, {useEffect} from 'react';
import {BaseProps} from "../../../../infrastructure/models/props/base-props";
import {DeviceViewModel} from "../../../models/device.view-model";
import {Tooltip} from "../../tooltip/tooltip";
import {Clear} from "../../../icons/clear";
import {Button} from "../buttons/button";
import {KEY_CODES} from "../../../consts";
import {WithDevice} from "../../composers/with-device";
import styles from "./popup.module.scss";
import classNames from "classnames";
import {Modal} from "../../../../modal";
import {ScrollBlocker} from "../blockers/scroll-blocker";
import type {BaseStyle} from "../../../../infrastructure/models/base-style";
import {useThemeColor} from "../../../../infrastructure/hooks/useThemeColor";
import {COLORS} from "../../../app/consts/colors";

export class PopupProps extends BaseProps {
  open: Boolean;
  onClose: Function;
  className: String
  style: BaseStyle;
  closeTooltip: String;
  clearButtonColor: String;
  device: DeviceViewModel;
  shouldRenderClearButton: Boolean;
  blockScrollToTop: Boolean;
}

export const Popup = WithDevice(({
                                   onClose,
                                   className,
                                   closeTooltip,
                                   clearButtonColor,
                                   device,
                                   style,
                                   shouldRenderClearButton,
                                   shouldRenderBlocker,
                                   children,
                                   dir,
                                 }: PopupProps) => {
  const {isRtl, isSmallView} = device;

  useEffect(() => {
    const onEscapeClick = (event) => {
      const onPressEscape: Boolean = event.keyCode === KEY_CODES.escape;
      onPressEscape && onClose()
    }
    window.addEventListener('keydown', onEscapeClick);
    return () => {
      window.removeEventListener('keydown', onEscapeClick);
    };
  }, []);

  const containerProps = {
    className: classNames(className, styles.window),
    dir,
    style
  }

  const buttonProps = {
    className: classNames(styles.button, {[styles.button_small_view]: isSmallView}),
    onClick: () => onClose && onClose()
  }

  const clearButtonProps = {
    style: {
      fill: clearButtonColor || useThemeColor(COLORS.textSecondary),
    }
  }

  return (
    <Modal isRtl={isRtl}>
      {
        shouldRenderBlocker === false ? null : <ScrollBlocker
          onDisable={() => onClose && onClose()}
          blanket={true}
          blockScrolling={true}
          disableClick={true}
          blockScrollToTop={true}
        />
      }

      <div {...containerProps}>
        <Button {...buttonProps}>
          <Tooltip title={closeTooltip}>
            {
              shouldRenderClearButton && <Clear {...clearButtonProps}/>
            }
          </Tooltip>
        </Button>
        {
          children
        }
      </div>
    </Modal>
  )
});

import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {SecondNoticeForNotification} from "../../../../core/components/second-notice-for-notification/second-notice-for-notification";
import {ACTION_TYPES} from "../../../../core/consts";

export const WebsiteModuleSecondNotice = observer(({controller}: WebsiteModuleProps) => {
  const secondNoticeProps = {
    isSmallView: controller.device.isSmallView,
    text: controller.terms.WEB_NOTIFICATIONS_2ND_NOTICE_TEXT,
    onPopupDisableClick: () => controller.action(ACTION_TYPES.notificationSecondNoticeDisplay, {isVisible: false})
  }

  return (
    controller.state.shouldRenderSecondNoticeMessage && <SecondNoticeForNotification {...secondNoticeProps}/>
  )
});

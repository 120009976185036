import {AnalyticsService} from "../../../../third-party-services/analytics/analytics-service";
import {PrivacySettingsNoticeDisplayEvent} from "../../../models/events/privacy-settings.notice-display.event";
import {LocalStorageUtils} from "../../../../infrastructure/utils/local-storage.utils";
import {AnalyticsEventHandler} from "../../../../infrastructure/handlers/analytics-event-handler";
import {PopupsDisplayManager} from "../../../managers/popups-display.manager";
import {POPUP_DISPLAY} from "../../../models/popup/consts";

export class PrivacySettingsNoticeDisplayHandler extends AnalyticsEventHandler {
  analyticsService: AnalyticsService;
  localStorageUtils: LocalStorageUtils;
  popupsDisplayManager: PopupsDisplayManager;

  static injectables = [AnalyticsService, LocalStorageUtils, PopupsDisplayManager];

  constructor(analyticsService: AnalyticsService, localStorageUtils: LocalStorageUtils, popupsDisplayManager: PopupsDisplayManager) {
    super(analyticsService);

    this.localStorageUtils = localStorageUtils;
    this.popupsDisplayManager = popupsDisplayManager;
  }

  async action(params) {
    const displayCounter = this.popupsDisplayManager.setPopupDisplayStatus(POPUP_DISPLAY.AUTO_DISPLAY_POPUPS.didomi, true)

    const sendParams = {
      timeShown: displayCounter,
      source: this.appStore.state.appPrivacyDisplayModel.source
    }

    const clickEvent: PrivacySettingsNoticeDisplayEvent = new PrivacySettingsNoticeDisplayEvent(sendParams, this.noticeEventType);

    await this.analyticsService.createEvent(clickEvent);
  }
}

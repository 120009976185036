import React, {useState, useCallback} from "react";
import {useEventListener} from "../../../../infrastructure/hooks/useEventListener";

export const ScrollButtonComposer = (Widget) => ({controller}) => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = useCallback(() => {
    controller.domUtils.scrollToTop();
  }, []);

  const onScroll = useCallback(() => {
    if (window.pageYOffset > 0 && !visible) {
      setVisible(true);
    }

    if (window.pageYOffset === 0 && visible) {
      setVisible(false);
    }
  }, [visible]);

  useEventListener('scroll', onScroll)

  const newProps = {
    scrollToTop: () => scrollToTop(),
    controller,
    visible
  };

  return <Widget {...newProps} />;
};

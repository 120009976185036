import {ActionHandler} from "./action-handler";
import {ACTION_TYPES, COMPONENT_STATES} from "../../core/consts";
import {ModuleController} from "../controllers/module-controller";

export class PopulateDataHandler extends ActionHandler {
  type = ACTION_TYPES.populateData;

  onInitialized(controller: ModuleController) {
    super.onInitialized(controller);

    if(controller.props.location?.state?.dataSource){
      this.action(controller.props.location.state);
    }
  }

  async action(params) {
    if(params?.dataSource) {
      this.state.dataSource = params.dataSource;

      this.store.setComponentState(COMPONENT_STATES.loaded);
    }
  }
}

import {AnalyticsService} from "../../../../third-party-services/analytics/analytics-service";
import {DIDOMI_NOTICE} from "../../../consts";
import {LocalStorageUtils} from "../../../../infrastructure/utils/local-storage.utils";
import {PrivacySettingsNoticeClickHandler} from "./privacy-settings.notice-click.handler";

export class PrivacySettingsPurposesClickHandler extends PrivacySettingsNoticeClickHandler {
  type = DIDOMI_NOTICE.ACTION_TYPES.purposesClick;
  analyticsService: AnalyticsService;
  localStorageUtils: LocalStorageUtils;

  static injectables = [AnalyticsService, LocalStorageUtils];

  get noticeEventType() {
    return DIDOMI_NOTICE.EVENT_TYPES.purposes
  }

  get noticeStorageKey() {
    return DIDOMI_NOTICE.KEYS.purposes
  }
}

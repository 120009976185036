import React from 'react';

import classnames from "classnames";
import styles from '../styles/web-to-app-cta-module.module.scss';
import {WebToAppCtaModuleButtons} from "./web-to-app-cta-module.buttons";
import {WebToAppCtaModuleContentAppLogo} from "./web-to-app-cta-module.content.app-logo";
import {WebToAppCtaModuleContentTexts} from "./web-to-app-cta-module.content.texts";

export const WebToAppCtaModuleContent = (({controller, onClick}) => {
  const {device: {isRtl}} = controller;

  const containerProps = {
    className: classnames(styles.content, {[styles.rtl]: isRtl}, {[styles.ltr]: !isRtl})
  }

  return (
    <div {...containerProps}>
      <div className={classnames(styles.main_container)}>
        <WebToAppCtaModuleContentAppLogo/>
        <WebToAppCtaModuleContentTexts controller={controller}/>
      </div>
      <WebToAppCtaModuleButtons controller={controller} onClick={onClick}/>
    </div>
  )
});

import { CoralogixRum } from '@coralogix/browser';


export const initCoralogix = () => {
  if(process.env.REACT_APP_CORALOGIX_APP_NAME) {
    CoralogixRum.init({
      application: process.env.REACT_APP_CORALOGIX_APP_NAME,
      environment: process.env.PRODUCTION_TYPE,
      public_key: process.env.REACT_APP_CORALOGIX_PUBLIC_KEY,
      coralogixDomain: process.env.REACT_APP_CORALOGIX_DOMAIN,
      version: `v${process.env.REACT_APP_VERSION_NUMBER}`,
      instrumentations: {
        xhr: false,
        fetch: false,
        web_vitals: true,
        interactions: true,
        custom: false,
        errors: true,
        long_tasks: true,
        resources: false
      },
      sessionSampleRate: 20,
    });
  }

}
const env = process.env;
export const ARGS = {
  NODE_ENV: env.NODE_ENV,
  IMAGES_API: env.REACT_APP_IMAGES_API,
  NOTIFICATIONS_IMAGES_API: env.REACT_APP_NOTIFICATIONS_IMAGES_API,
  NOTIFICATIONS_IMAGES_API_APPSPOT: env.REACT_APP_NOTIFICATIONS_IMAGES_API_APPSPOT,
  WEBSITE_DOMAIN: env.REACT_APP_WEBSITE_DOMAIN,
  PARTNERS_DOMAIN: env.REACT_APP_PARTNERS_DOMAIN,
  WEBSITE_DEFAULT_POSTFIX: env.REACT_APP_WEBSITE_DEFAULT_POSTFIX,
  VERSION_NUMBER: env.REACT_APP_VERSION_NUMBER,
  PRODUCTION_TYPE: env.REACT_APP_PRODUCTION_TYPE,
  PRODUCTION_USERS_API: env.REACT_APP_PRODUCTION_USERS_API,
  QA_USERS_API: env.REACT_APP_QA_USERS_API,
  DIDOMI_API_KEY: env.REACT_APP_DIDOMI_API_KEY,
  DIDOMI_NOTICE_ID: env.REACT_APP_DIDOMI_NOTICE_ID,
  FIREBASE_API_KEY: env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_APP_ID: env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_PROJECT_ID: env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_MIN_FETCH_INTERVAL: env.REACT_APP_FIREBASE_MIN_FETCH_INTERVAL,
  EXPOSE_ALIAS_NAMES: env.REACT_APP_EXPOSE_ALIAS_NAMES,
  NO_INDEX: env.REACT_APP_NO_INDEX,
  KINESIS_IDENTITY_POOL_ID: env.REACT_APP_KINESIS_IDENTITY_POOL_ID,
  KINESIS_REGION_NAME: env.REACT_APP_KINESIS_REGION_NAME,
  EXPOSE_DEBUG: env.REACT_APP_EXPOSE_DEBUG,
  SPORTS_WIDGETS_DOMAIN: env.REACT_APP_SPORTS_WIDGETS_DOMAIN,
}

import React from 'react';

import classnames from "classnames";
import styles from '../styles/web-to-app-cta-module.module.scss';

export const WebToAppCtaModuleContentTexts = (({controller}) => {
  const {state: {terms: {WEB_TO_APP_MAIN_TEXT, WEB_TO_APP_SECONDARY_TEXT}}} = controller;

  const titleProps = {
    className: classnames(styles.title),
  }

  const textProps = {
    className: classnames(styles.text),
  }

  return (
    <div className={classnames(styles.text_container)}>
      <div {...titleProps}>{WEB_TO_APP_MAIN_TEXT}</div>
      <div {...textProps}>{WEB_TO_APP_SECONDARY_TEXT}</div>
    </div>
  )
});

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import styles from "./dynamic-popup.module.scss";
import classNames from "classnames";
import {Popup} from "../misc/dialog/popup";
import {COLORS} from "../../consts";
import {POPUP_DISPLAY} from "../../models/popup/consts";

export const DynamicPopup = observer(({controller, sportTypeId}) => {
  const {state, device} = controller;
  const {appState} = state;
  const {isSmallView} = device;
  const {dynamicPopupModel, locale, popupsDisplayManager} = appState;
  const {userCountryId} = locale;
  const {backgroundImage, title, subtitle, buttonText, buttonColor, url, isExternal, disclaimer} = dynamicPopupModel;

  const [shouldRenderPopup: Boolean, setShouldRenderPopup] = useState(false);
  useEffect(() => {
    popupsDisplayManager.registerToChange(POPUP_DISPLAY.AUTO_DISPLAY_POPUPS.dynamicPopup,
      (params) => setTimeout(() => displayPopup(), params.delay * 1000))
  }, []);

  const displayPopup = () => {
    let isVisible: Boolean = false;

    if(dynamicPopupModel && sportTypeId > 0) {
      isVisible = dynamicPopupModel?.isValidByUserCountryId(userCountryId)
      isVisible &= dynamicPopupModel?.isValidBySportTypeId(sportTypeId);

      setShouldRenderPopup(isVisible)
    }

    popupsDisplayManager.setPopupDisplayStatus(POPUP_DISPLAY.AUTO_DISPLAY_POPUPS.dynamicPopup, isVisible)
  }

  const popupProp = {
    className: classNames(styles.popup, {[styles.small_view]: isSmallView}),
    shouldRenderClearButton: true,
    onClose: () => setShouldRenderPopup(false),
    clearButtonColor: COLORS.secondaryBackground
  }

  const containerProps = {
    className: classNames(styles.container),
    style: {
      backgroundImage: `url(${backgroundImage})`
    }
  }

  const buttonProps = {
    className: classNames(styles.button),
    style: {
      backgroundColor: buttonColor
    },
    onClick: () => {
      url && controller.navigationManager.gotoUrl(url, false, isExternal);
      setShouldRenderPopup(false)
    }
  }

  return (
    shouldRenderPopup ? <Popup {...popupProp}>
      <div {...containerProps}>
        <div className={classNames(styles.title)}>{title}</div>
        <div className={classNames(styles.subtitle)}>{subtitle}</div>
        <div {...buttonProps}>{buttonText}</div>
        <div className={classNames(styles.disclaimer)}>{disclaimer}</div>
      </div>
    </Popup> : null
  )
});

import {AnalyticsService} from "../../third-party-services/analytics/analytics-service"
import {ExternalAdDisplayEvent} from "../../core/models/events/external-ad.display-event";
import {ExternalAdClickEvent} from "../../core/models/events/external-ad.click-event";

export const useExternalAdAnalyticsEvent = (controller) => {
  const analyticsService: AnalyticsService = controller.props.ioc.getInstance(AnalyticsService);

  // Function to trigger a display event
  const trackDisplayEvent = (params) => {
    analyticsService.createEvent(new ExternalAdDisplayEvent(params));
  };

  // Function to trigger a click event
  const trackClickEvent = (params) => {
    analyticsService.createEvent(new ExternalAdClickEvent(params));
  };

  // Return these functions to use in your component
  return {
    trackDisplayEvent,
    trackClickEvent,
  };
};

import React, {lazy, Suspense} from 'react';

import {WebsiteModuleFooter} from "./website-module.footer";
import {observer} from "mobx-react";
import {WebsiteModuleProps} from "../models/website-module.props";
import {WebsiteModuleController} from "../website-module.controller";
import {FloatingComponent} from "../../../core/components/floating-button/floating-component";
import {BolaoFloatingButton} from "../../../core/components/floating-button/bolao/bolao-floating-button";
import {ACTION_TYPES} from "../../../core/consts";
import styles from "../styles/website.module.scss";
import classnames from "classnames";
import {SiteHeaderModuleAsync} from "../modules/site-header-module/components/site-header-module.async";

const WebsiteModuleBody = lazy(() => import('./website-module.main'));

export class WebsiteFrameComponentProps extends WebsiteModuleProps {
  controller: WebsiteModuleController;
}

export const WebsiteModuleFrame = observer(({controller}: WebsiteFrameComponentProps) => {
  const {state} = controller;
  const {bolaoSettings} = state;
  const {isSmallView} = state.device;

  const floatingButtonProps = {
    onDrop: () => {
      controller.action(ACTION_TYPES.floatingButtonRemoveEvent);
      state.showFloatingButton = false;
    },
    onDisplay: () => {
      controller.action(ACTION_TYPES.floatingButtonDisplayEvent);
    },
    ButtonComponent: BolaoFloatingButton,
    buttonComponentsProps: {
      url: bolaoSettings?.assets?.floating
    },
    onClick: () => {
      controller.action(ACTION_TYPES.floatingButtonClick, {url: bolaoSettings?.gameUrl})
    },
    isRtl: state.device.isRtl
  }

  const bodyProps = {
    className: classnames({[styles.small_view]: isSmallView}, styles.main_placeholder),
  }

  return (
    <>
      {
        state.shouldRenderFloatingButton && <FloatingComponent {...floatingButtonProps} />
      }
      <SiteHeaderModuleAsync isSmallView={isSmallView}/>
      <Suspense fallback={<div {...bodyProps}/>}>
        <WebsiteModuleBody controller={controller}/>
        <WebsiteModuleFooter controller={controller}/>
      </Suspense>
    </>
  );
});

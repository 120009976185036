import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {ScrollButton} from "../../../../core/components/misc/scroll-button/scroll-button";

export const WebsiteModuleScrollButton = observer(({controller}: WebsiteModuleProps) => {
  return (
    controller.device.isLargeView ? <ScrollButton controller={controller}/> : null
  )
});

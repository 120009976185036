import React from 'react';

import {BaseProps} from "../../../infrastructure/models/props/base-props";
import {ScrollBlocker} from "../misc/blockers/scroll-blocker";
import {useThemeColor} from "../../../infrastructure/hooks/useThemeColor";
import {NotificationsLockImageRequestParams} from "./notifications-lock-image.request-params";
import {SVGImage} from "../image/svg-image";
import {COLORS} from "../../app/consts/colors";

export class SecondNoticeForNotificationComponentProps extends BaseProps {
  isSmallView: Boolean;
  text: Boolean;
  onPopupDisableClick: Function;
}

export const SecondNoticeForNotification = (props: SecondNoticeForNotificationComponentProps) => {
  const scrollBlockerProp = {
    onDisable: () => props.onPopupDisableClick(),
    blanket: props.isSmallView,
    blockScrolling: true,
  };

  const logoWidth: Number = props.isSmallView ? 11 : 12;
  const logoHeight: Number = props.isSmallView ? 14 : 16;

  const iconProps = {
    className: 'second-notice-for-notification-icon-container',
    src: new NotificationsLockImageRequestParams(logoWidth, logoHeight),
    fillColor: useThemeColor(COLORS.whitePrimary)
  };

  return (
    <div className={['second-notice-for-notification', props.isSmallView ? 'second-notice-for-notification-small-view' : ''].join(' ')}>
      <ScrollBlocker {...scrollBlockerProp}/>
      {
        !props.isSmallView && <div className={'second-notice-for-notification-arrow-up'}/>
      }
      <div className={'second-notice-for-notification-container'}>
        <SVGImage {...iconProps}/>
        {
          props.text
        }
      </div>
    </div>
  )
}

import {ACTION_TYPES} from "../consts";
import {ActionHandler} from "../../infrastructure/handlers/action-handler";

export class DidomiNoticeInitHandler extends ActionHandler {
  type = ACTION_TYPES.initDidomiHandler;
  static injectables = [...ActionHandler.injectables];

  async action(params) {
   this.appStore.state.didomiObject = params.didomi;
  }
}

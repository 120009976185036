import React from 'react';
import {AsyncComponent} from "../../../../../core/components/router/async-component";

const NotificationApprovalModuleAsyncComponent = AsyncComponent(async () => import('./notification-approval-module' /* webpackChunkName: "notification-approval-module" */ ));

export const NotificationApprovalModuleAsync = (props) => {
  return <React.Suspense fallback={<div/>}>
    <NotificationApprovalModuleAsyncComponent {...props}/>
  </React.Suspense>
}

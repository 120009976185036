import {BottomMessageModuleController} from "../bottom-message-module.controller";
import {ActionHandler} from "../../../../../infrastructure/handlers/action-handler";
import {BOTTOM_MESSAGE} from "../bottom-message-module.consts";
import {LOCAL_STORAGE_KEYS} from "../../../../../core/consts";
import {BottomMessageModuleState} from "../models/bottom-message-module.state";
import {LocalStorageUtils} from "../../../../../infrastructure/utils/local-storage.utils";
import { runInAction } from "mobx"
import {PopupsDisplayManager} from "../../../../../core/managers/popups-display.manager";
import {POPUP_DISPLAY} from "../../../../../core/models/popup/consts";
import {WEBSITE_MODULE} from "../../../website-module.consts";

export class BottomMessageModuleClickHandler extends ActionHandler {
  type = BOTTOM_MESSAGE.ACTION_TYPES.bottomMessageClick;
  controller: BottomMessageModuleController;
  state: BottomMessageModuleState;

  static injectables = [...ActionHandler.injectables, LocalStorageUtils, PopupsDisplayManager];

  constructor(localStorageUtils: LocalStorageUtils, popupsDisplayManager: PopupsDisplayManager) {
    super();

    this.localStorageUtils = localStorageUtils;
    this.popupsDisplayManager = popupsDisplayManager;
  }

  async action() {
    this.localStorageUtils.set(LOCAL_STORAGE_KEYS.cookiePolicyApproved, true);
    this.popupsDisplayManager.disablePopup(POPUP_DISPLAY.AUTO_DISPLAY_POPUPS.didomi);

    this.controller.action(WEBSITE_MODULE.ACTION_TYPES.didomiWidgetAutoDisplayHandler, {display: false})

    runInAction(() => {
      this.state.appState.isCookiesPolicyApproved = true;
    })
  }
}

import {WebToAppCtaModuleController} from "../web-to-app-cta-module.controller";
import {WEB_TO_APP_CTA_CONSTS} from "../web-to-app-cta-module.consts";
import {WebToAppCtaModuleState} from "../models/web-to-app-cta-module.state";
import {WebToAppCtaModuleOnClickAnalyticsEvent} from "../models/web-to-app-cta-module.on-click.analytics-event";
import {AnalyticsService} from "../../../../../third-party-services/analytics/analytics-service";
import {GuidClickEventHandler} from "../../../../../core/handlers/guid.click-event.handler";

export class WebToAppCtaModuleOnClickHandler extends GuidClickEventHandler {
  type = WEB_TO_APP_CTA_CONSTS.ACTION_TYPES.onClose;
  controller: WebToAppCtaModuleController;
  state: WebToAppCtaModuleState;

  static injectables = [...GuidClickEventHandler.injectables];

  constructor(analyticsService: AnalyticsService) {
    super(analyticsService);

    this.eventType = WebToAppCtaModuleOnClickAnalyticsEvent;
  }

  async action(params) {
    const sendParams = {
      ...params,
      screen: this.getScreen(this.state.currentLinkModel.name),
    }

    super.action(sendParams);

    if(params.clickType === WEB_TO_APP_CTA_CONSTS.CLICK_TYPES.app) {
      this.navigationManager.gotoUrl(this.controller.props.deepLink, false, true);
    }

  }
}

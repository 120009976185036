import {ModuleController} from "../../../../infrastructure/controllers/module-controller";
import {BaseManagedService} from "../../../../infrastructure/domain-services/base-managed-service";
import {WebToAppCtaModuleConfig} from "./web-to-app-cta-module.config";
import {WebToAppCtaModuleStore} from "./web-to-app-cta-module.store";
import {WebToAppCtaModuleHandlers} from "./handlers/web-to-app-cta-module.handlers";

export class WebToAppCtaModuleController extends ModuleController {
  static injectables = [...BaseManagedService.injectables, WebToAppCtaModuleConfig, WebToAppCtaModuleStore, WebToAppCtaModuleHandlers];

}

import React from 'react';

import classnames from "classnames";
import styles from '../styles/web-to-app-cta-module.module.scss';
import {Image} from "../../../../../core/components/image/image";
import {WebToAppCtaLogoImageRequestParams} from "../models/web-to-app-cta.logo.image-request-params";
import {WebToAppCtaStarImageRequestParams} from "../models/web-to-app-cta.star.image-request-params";


export const WebToAppCtaModuleContentAppLogo = (() => {
  const logoProps = {
    className: classnames(styles.logo),
    src: new WebToAppCtaLogoImageRequestParams({
      width: 48,
      height: 48
    })
  }

  const starImageParams: WebToAppCtaStarImageRequestParams = new WebToAppCtaStarImageRequestParams({
    width: 8,
    height: 8
  });

  return (
    <div className={classnames(styles.logo_container)}>
      <Image {...logoProps} />
      <div className={classnames(styles.stars)}>
        <Image src={starImageParams}/>
        <Image src={starImageParams}/>
        <Image src={starImageParams}/>
        <Image src={starImageParams}/>
        <Image src={starImageParams}/>
      </div>
    </div>
  )
});

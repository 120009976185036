import React from 'react';

import {WebsiteModuleSecondNotice} from "./conditional/website-module.second-notice";
import {WebsiteModuleWizard} from "./conditional/website-module.wizard";
import {WebsiteModuleNotificationApprovalMessage} from "./conditional/website-module.notification-approval-message";
import {WebsiteModuleBottomMessage} from "./conditional/website-module.bottom-message";
import {WebsiteFrameBottomNavigation} from "./bottom-mobile/website-module.bottom";
import {WebsiteModuleUsPopup} from "./website-module-us-popup";
import {WebsiteModuleBettingPromotion} from "./conditional/website-module.betting-promotion";
import {WebsiteModuleWebToAppCta} from "./conditional/website-module.web-to-app-cta";
import {WebsiteModuleSearchContent} from "./conditional/website-module.search-content";
import {WebsiteModuleDynamicPopup} from "./conditional/website-module.dynamic-popup";

export const WebsiteModulePopups = ({controller, pushNotificationRequestPermission}) => {
  const wizardWidgetProps = {
    pushNotificationRequestPermission: pushNotificationRequestPermission,
    controller: controller,
  };

  return (
    <>
      <WebsiteModuleWebToAppCta controller={controller}/>
      <WebsiteModuleBettingPromotion controller={controller}/>
      <WebsiteModuleNotificationApprovalMessage controller={controller}/>
      <WebsiteModuleSecondNotice controller={controller}/>
      <WebsiteModuleWizard {...wizardWidgetProps}/>
      <WebsiteModuleBottomMessage controller={controller}/>
      <WebsiteFrameBottomNavigation controller={controller}/>
      <WebsiteModuleUsPopup controller={controller}/>
      <WebsiteModuleSearchContent controller={controller}/>
      <WebsiteModuleDynamicPopup controller={controller}/>
    </>
  )
}

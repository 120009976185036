import {ActionHandler} from "../../../../infrastructure/handlers/action-handler";
import {DIDOMI_NOTICE} from "../../../consts";

export class PrivacySettingsSourceChangeHandler extends ActionHandler {
  type = DIDOMI_NOTICE.ACTION_TYPES.onSourceChange;

  static injectables = [...ActionHandler.injectables];

  async action(params) {
    this.appStore.state.appPrivacyDisplayModel.source = params.source;

    return params;
  }
}

import {AnalyticsService} from "../../../../third-party-services/analytics/analytics-service";
import {DIDOMI_NOTICE} from "../../../consts";
import {LocalStorageUtils} from "../../../../infrastructure/utils/local-storage.utils";
import {PrivacySettingsNoticeDisplayHandler} from "./privacy-settings.notice-display.handler";
import {AppController} from "../../../app/app-controller";
import {PopupsDisplayManager} from "../../../managers/popups-display.manager";

export class PrivacySettingsPopupDisplayHandler extends PrivacySettingsNoticeDisplayHandler {
  type = DIDOMI_NOTICE.ACTION_TYPES.popupDisplay;
  controller: AppController;
  analyticsService: AnalyticsService;
  localStorageUtils: LocalStorageUtils;

  static injectables = [AnalyticsService, LocalStorageUtils, PopupsDisplayManager];

  async action(params): Promise<void> {
    await this.controller.action(DIDOMI_NOTICE.ACTION_TYPES.onSourceChange, {source: DIDOMI_NOTICE.ANALYTICS.SOURCES.popup})

    await super.action(params);
  }

  get noticeEventType() {
    return DIDOMI_NOTICE.EVENT_TYPES.popup
  }

  get noticeStorageKey() {
    return DIDOMI_NOTICE.KEYS.popup
  }
}

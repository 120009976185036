import React, {useEffect} from 'react';

import {DomUtils} from "../../../../infrastructure/utils/dom-utils";
import {UseIoC} from "../../composers/composer-plugins/use-ioc";
import {BaseProps} from "../../../../infrastructure/models/props/base-props";
import {IOC} from "../../../../infrastructure/ioc";
import {BackgroundBlocker} from "./background-blocker";

export class ScrollBlockerProps extends BaseProps {
  onDisable: Function;
  disableClick: Boolean;
  blanket: Boolean;
  blockScrolling: Boolean;
  blockScrollToTop: Boolean;
  ioc: IOC;
}

export const ScrollBlocker = UseIoC()((props: ScrollBlockerProps) => {
  const domUtils: DomUtils = props.ioc.getInstance(DomUtils);

  useEffect(() => {
    props.blockScrolling && domUtils.blockScrolling(true);
    props.blockScrollToTop && domUtils.blockScrollToTop(true)

    return () => {
      props.blockScrolling && domUtils.blockScrolling(false);
      props.blockScrollToTop && domUtils.blockScrollToTop(false);
    }
  }, []);

  const backgroundBlockerProps = {
    onDisable: () => props.onDisable && props.onDisable(),
    blanket: props.blanket,
    disableClick: props.disableClick,
  };

  return (
    <>
      <BackgroundBlocker {...backgroundBlockerProps}>
      {
        props.children || null
      }
      </BackgroundBlocker>
    </>
  );
});

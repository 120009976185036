import React, {forwardRef, useRef, useState} from 'react'
import {BaseProps} from "../../../../infrastructure/models/props/base-props";
import classNames from "classnames";

export class ActionButtonProps extends BaseProps {
  onClick: Function;
  className: String;
}

export const ActionButton = forwardRef((props: ActionButtonProps, forwardRef) => {
  const ref = useRef(forwardRef);
  const [active, setActive] = useState(false);

  const containerProps = {
    ref: forwardRef,
    className: classNames(props.className || 'action-button'),
    onClick: (e) => {
      ref.current.style.left = `${e.nativeEvent.offsetX}px`;
      ref.current.style.top = `${e.nativeEvent.offsetY}px`;
      setActive(true);

      setTimeout(() => {
        props.onClick && props.onClick();
        setActive(false)
      }, 150);
    },
    style: props.style
  }

  return (
    <div {...containerProps}>
      <div ref={ref} className={active ? 'action-button-circle' : ''}/>
      {
        props.children
      }
    </div>
  )
})

import {BaseAnalyticsEvent} from "../../../../../infrastructure/models/analytics/base-analytics-event";

export class WebToAppCtaModuleOnClickAnalyticsEvent extends BaseAnalyticsEvent {
  clickType: Number;
  guid: Number;
  screen: String;

  constructor(params) {
    super('', 'click', 'cta');

    this.clickType = params.clickType;
    this.guid = params.guid;
    this.screen = params.screen;
  }

  get properties() {
    const properties = super.properties;

    properties.clickType = this.clickType;
    properties.guid = this.guid;
    properties.screen = this.screen;

    return properties;
  }
}

import {BaseService} from "./infrastructure/domain-services/base-service";
import {Type} from "./infrastructure/type";
import {contractsUtils} from "./infrastructure/utils/contracts-utils";
import {makeObservable} from 'mobx';
import {ARGS} from "./core/bootstrap/args";

export class InversionContainer extends BaseService {
  singletons: Object<Type>;

  constructor() {
    super();

    this.singletons = {}
  }

  registerSingleton(type: Type<BaseService>, constantValue) {
    let instance = constantValue;

    if (!instance) {
      instance = this.getInstance(type);
    }

    this.assign(instance);
    return instance;
  }

  getInstance(type) {
    contractsUtils.required(type);

    let instance = this.singletons[type.name];

    if (!instance) {
      if (type.dev_only && ARGS.PRODUCTION_TYPE === 'production') {
        return null;
      }

      const params = type.injectables?.map(t => this.getInstance(t)) || [];
      instance = new type(...params);

      if (type.isSingleton) {
        this.assign(type, instance);
      }

      if (type.isObservable) {
        makeObservable(instance);
      }
    }

    return instance;
  }

  assign(type, instance) {
    this.singletons[type.name] = instance;
  }

  getAllInstanceOf(requiredType: Type) {
    return Object.values(this.singletons).filter((type: Type) => type instanceof requiredType);
  }
}

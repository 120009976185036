import {AnalyticsService} from "../../../../third-party-services/analytics/analytics-service";
import {DIDOMI_NOTICE} from "../../../consts";
import {LocalStorageUtils} from "../../../../infrastructure/utils/local-storage.utils";
import {AppController} from "../../../app/app-controller";
import {PrivacySettingsNoticeClickHandler} from "./privacy-settings.notice-click.handler";

export class PrivacySettingsPopupClickHandler extends PrivacySettingsNoticeClickHandler {
  type = DIDOMI_NOTICE.ACTION_TYPES.popupClick;
  controller: AppController;
  analyticsService: AnalyticsService;
  localStorageUtils: LocalStorageUtils;

  static injectables = [AnalyticsService, LocalStorageUtils];

  get noticeEventType() {
    return DIDOMI_NOTICE.EVENT_TYPES.popup
  }

  get noticeStorageKey() {
    return DIDOMI_NOTICE.KEYS.popup
  }
}

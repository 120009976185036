import React from 'react-dom'
import {DropArea} from "./drop-area";
import {FloatingButton} from "./floating-button";
import {FLOATING_BUTTON} from "./floating-button-consts";
import {useEffect, useRef, useState} from "react";

export const FloatingComponent = ({onDrop, ButtonComponent, buttonComponentsProps, onClick, onDisplay, isRtl}) => {
  const size = FLOATING_BUTTON.SIZE;
  const [isDragging, setIsDragging] = useState(false);
  const [isCollision, setIsCollision] = useState(false);

  const drop = useRef(null);
  const drag = useRef(null);

  useEffect(() => {
    onDisplay && onDisplay();
  }, [])

  const onDrag = (e, data) => {
    setIsDragging(true);
    const isCollision = collision(drag.current?.getBoundingClientRect(), drop.current?.getBoundingClientRect(), size)
    setIsCollision(isCollision);
  }

  const onStop = (e, data) => {
    if (isCollision) {
      onDrop && onDrop()
    }
    if (!isDragging) {
      onClick && onClick()
    }

    setIsDragging(false);
  }

  const draggableItemProps = {
    onDrag,
    onStop,
    ButtonComponent,
    buttonComponentsProps,
    setIsDragging,
    isCollision,
    setIsCollision,
    size,
    isRtl
  }

  const dropComponentProps = {
    isDragging,
    isCollision,
    size
  }

  return (
    <>
      <FloatingButton ref={drag} {...draggableItemProps}/>
      <DropArea ref={drop} {...dropComponentProps}/>
    </>
  )
}

function collision(drag: DOMRect, drop: DOMRect, offset = 32) {
  const leftDrag = drag.left;
  const topDrag = drag.top;

  const leftDrop = drop.left - offset;
  const leftEndDrop = drop.left + drop.width;
  const topDrop = drop.top - offset;
  const topEndDrop = drop.top + drop.height;

  const isCollision = leftDrop < leftDrag && topDrag > topDrop && leftDrag < leftEndDrop && topDrag < topEndDrop;

  return isCollision;
}

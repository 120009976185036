import React from 'react';

import classnames from "classnames";
import styles from '../styles/web-to-app-cta-module.module.scss';
import {Button} from "../../../../../core/components/misc/buttons/button";
import {WEB_TO_APP_CTA_CONSTS} from "../web-to-app-cta-module.consts";


export const WebToAppCtaModuleButtons = (({controller, onClick}) => {
  const {state: {terms: {WEB_TO_APP_OPEN_APP, WEB_TO_APP_OPEN_BROWSER}}} = controller;

  const containerProps = {
    className: classnames(styles.buttons)
  }

  const openButtonProps = {
    className: classnames(styles.button, styles.filled_button),
    label: WEB_TO_APP_OPEN_APP,
    onClick: () => onClick(WEB_TO_APP_CTA_CONSTS.CLICK_TYPES.app)
  }

  const closeButtonProps = {
    className: classnames(styles.button, styles.unfilled_button),
    label: WEB_TO_APP_OPEN_BROWSER,
    onClick: () => onClick(WEB_TO_APP_CTA_CONSTS.CLICK_TYPES.browser)
  }

  return <div {...containerProps}>
    <Button {...openButtonProps}/>
    <Button {...closeButtonProps}/>
  </div>
});

import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {WizardWidgetAsync} from "../../../../widgets/wizard-widget/components/wizard-widget.async";
import {APP} from "../../../../core/app/consts";

export const WebsiteModuleWizard = observer(({controller, pushNotificationRequestPermission}: WebsiteModuleProps) => {
  const wizardWidgetProps = {
    isVisible: controller.state.appState.wizardDisplayModel.isVisible,
    page: controller.state.appState.wizardDisplayModel.page,
    onClose: () => pushNotificationRequestPermission(APP.ANALYTICS.NOTIFICATIONS.TRIGGER.wizard)
  }

  return (
    controller.state.shouldRenderWizard && <WizardWidgetAsync {...wizardWidgetProps} />
  )
});

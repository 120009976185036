import React from 'react';

import {observer} from "mobx-react";
import {BaseProps} from "../../../infrastructure/models/props/base-props";
import {Popup} from "../../../core/components/misc/dialog/popup";
import {ActionButton} from "../../../core/components/misc/buttons/action-button";
import {CountryFlagParams} from "../../../domain/common/country/models/country-flag-params";
import {Image} from "../../../core/components/image/image";
import {WEBSITE_MODULE} from "../website-module.consts";
import {WebsiteModuleController} from "../website-module.controller";
import styles from "../styles/website-us-popup.module.scss";
import classNames from "classnames";

export class WebsiteModuleUsPopupProps extends BaseProps {
  controller: WebsiteModuleController;
}

export const WebsiteModuleUsPopup = observer(({controller}: WebsiteModuleUsPopupProps) => {
  const onClick = (userAgree: Boolean) => controller.action(WEBSITE_MODULE.ACTION_TYPES.usPopup, {userAgree})

  const popupProps = {
    onClose: () => onClick(false),
    className: classNames(styles.popup, {[styles.small_view]: controller.device.isSmallView}),
    shouldRenderClearButton: true
  }

  const imageLogoProps = {
    className: classNames(styles.logo),
    src: new CountryFlagParams({width: 32, height: 32}, {id: WEBSITE_MODULE.US_USER_COUNTRY})
  }

  const approveButtonProps = {
    onClick: () => onClick(true),
    className: classNames(styles.button),
  }

  const declineButtonProps = {
    onClick: () => onClick(false),
    className: classNames(styles.button),
  }

  return (
    controller.state.isUSPopupVisible ? <Popup {...popupProps}>
      <div className={classNames(styles.title)}>
        <Image {...imageLogoProps} />
        <div className={classNames(styles.text)}>
          {controller.terms.CHANGE_COUNTRY_USA_POPUP}
        </div>
      </div>
      <div className={classNames(styles.buttons)}>
        <ActionButton {...approveButtonProps}>
          {
            controller.terms.WEB_POPUP_BUTTON_YES
          }
        </ActionButton>
        <ActionButton {...declineButtonProps}>
          {
            controller.terms.WEB_POPUP_BUTTON_NO
          }
        </ActionButton>
      </div>
    </Popup> : null
  )
});

import React from 'react';
import {Path} from "../../components/svg/components/path";
import {Svg} from "../../components/svg/components/Svg";
import classNames from "classnames";
import styles from "./keyboard-arrow.module.scss";

export const KeyboardArrowUp = ({style, className}) => {
  return <Svg className={classNames(className, styles.default)} style={style}>
    <Path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
  </Svg>
}

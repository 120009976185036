import {Image} from "../../image/image";
import styles from './floating-button.bolao.module.scss';
import classnames from "classnames";

export const BolaoFloatingButton = ({url}) => {

  const BolaoFloatingButtonParams = {
    src: url,
    className: classnames(styles.floating_button),
    eager: true
  }

  return (
    url ? <Image {...BolaoFloatingButtonParams}/> : null
  )
};

import React from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {NotificationApprovalModuleAsync} from "../../modules/notification-approval-module/components/notifications-approval-module.async";

export const WebsiteModuleNotificationApprovalMessage = observer(({controller}: WebsiteModuleProps) => {
  return (
    controller.state.shouldRenderTopMessage ? <NotificationApprovalModuleAsync controller={controller}/> : null
  )
});

import React from 'react';

import {BaseProps} from "../../../../infrastructure/models/props/base-props";

export class ButtonComponentProps extends BaseProps {
  className: String;
  label: String;
  onClick: Function = () => true;
}

export const Button = (props: ButtonComponentProps) => {
  const onClick = (e) => {
    props.onClick && props.onClick(e);
  }

  const buttonProps = {
    className: props.className,
    onClick: onClick,
    style: props.style
  }

  return (
    <button {...buttonProps}>
      {
        props.children || props.label
      }
    </button>
  )
}
